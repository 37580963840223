<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-10-29 15:37:37
 * @LastEditTime: 2021-10-29 17:16:08
 * @Description: 超限发货查看信息价中转页
 * @Param:
 * @FilePath: \JT_Web_test\src\views\salesBusiness\marketInforPrice\informationPriceView.vue
 -->

<template>
    <div class="informationPriceView" :extr="extrData" :is="currentView"></div>
</template>

<script>
export default {
    name: 'information-price-view',
    components: {},
    props: {
        vueFormParams: {
            type: [Object],
        },
    },
    data() {
        return {
            pageInfo: {},
            currentView: '',
            extrData: {},
        };
    },
    computed: {},
    watch: {},
    async created() {
        this.pageInfo = await this.getPageInfo(this.vueFormParams.businessInstanceId);
        this.currentView = () => import(`@/views/${this.pageInfo.page_url}.vue`);
        this.extrData = { cpvid: this.pageInfo.cpvid };
    },
    mounted() {},
    destroyed() {},
    methods: {
        getPageInfo(businessInstanceId) {
            const path = `/interfaceApi/sale/contract_transfinite/get_price_page/${businessInstanceId}`;
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped></style>